// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vis-timeline {
  border: none;
}

.vis-panel.vis-top, .vis-panel.vis-center {
  border-left: none;
}

.vis-label .vis-inner {
  margin-left: 5px;
}
.vis-label.vis-nested-group.vis-group-level-2 {
  background: white;
}

.vis-item.status_COMPLETED {
  background-color: #aee1b8;
  border-color: #163e1d;
  color: #163e1d;
}
.vis-item.status_COMPLETED_WITH_ERRORS {
  background-color: #feeac5;
  border-color: #8b5b02;
  color: #8b5b02;
}
.vis-item.status_IN_PROGRESS, .vis-item.status_SCHEDULED {
  background-color: #cbe2f7;
  border-color: #11497a;
  color: #11497a;
}
.vis-item.status_FAILED, .vis-item.status_FAILED_WITH_TERMINAL_ERROR, .vis-item.status_TIMED_OUT, .vis-item.status_DF_PARTIAL, .vis-item.status_CANCELED {
  background-color: #f9c6c9;
  border-color: #7f050b;
  color: #7f050b;
}
.vis-item.status_SKIPPED {
  background-color: #fff;
  border-color: gray;
  color: gray;
}
.vis-item.vis-selected {
  filter: brightness(70%);
}
.vis-item .vis-item-content {
  font-size: 10px;
  padding: 0px 3px 0px 3px;
}`, "",{"version":3,"sources":["webpack://./src/pages/execution/timeline.scss"],"names":[],"mappings":"AAMA;EACE,YAAA;AALF;;AASE;EAEE,iBAAA;AAPJ;;AAWE;EACE,gBAAA;AARJ;AAUE;EACE,iBAAA;AARJ;;AAaE;EAzBA,yBA0B6B;EAzB7B,qBAyBoB;EAxBpB,cAwBoB;AARtB;AAUE;EA5BA,yBA6B6B;EA5B7B,qBA4BoB;EA3BpB,cA2BoB;AANtB;AAQE;EA/BA,yBAiC6B;EAhC7B,qBAgCoB;EA/BpB,cA+BoB;AALtB;AAQE;EApCA,yBAyC6B;EAxC7B,qBAwCoB;EAvCpB,cAuCoB;AARtB;AAUE;EA3CA,sBADkC;EAElC,kBA2CoB;EA1CpB,WA0CoB;AANtB;AAQE;EACE,uBAAA;AANJ;AAQE;EACE,eAAA;EACA,wBAAA;AANJ","sourcesContent":["@mixin barColor($colorfg, $colorbg: #fff) {\n  background-color: $colorbg;\n  border-color: $colorfg;\n  color: $colorfg;\n}\n\n.vis-timeline {\n  border: none;\n}\n\n.vis-panel {\n  &.vis-top,\n  &.vis-center {\n    border-left: none;\n  }\n}\n.vis-label {\n  .vis-inner {\n    margin-left: 5px;\n  }\n  &.vis-nested-group.vis-group-level-2 {\n    background: white;\n  }\n}\n\n.vis-item {\n  &.status_COMPLETED {\n    @include barColor(#163e1d, #aee1b8);\n  }\n  &.status_COMPLETED_WITH_ERRORS {\n    @include barColor(#8b5b02, #feeac5);\n  }\n  &.status_IN_PROGRESS,\n  &.status_SCHEDULED {\n    @include barColor(#11497a, #cbe2f7);\n  }\n  //&.status_CANCELED { @include barColor(#26194b, #ded5f8); }\n  &.status_FAILED,\n  &.status_FAILED_WITH_TERMINAL_ERROR,\n  &.status_TIMED_OUT,\n  &.status_DF_PARTIAL,\n  &.status_CANCELED {\n    @include barColor(#7f050b, #f9c6c9);\n  }\n  &.status_SKIPPED {\n    @include barColor(gray);\n  }\n  &.vis-selected {\n    filter: brightness(70%);\n  }\n  .vis-item-content {\n    font-size: 10px;\n    padding: 0px 3px 0px 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
